export const isInpathPolicy = (catalog) => {
  return catalog.includes('INPATH');
};

export const getBrandNameForInpathPolicy = (catalog) => {
  switch (catalog) {
    case 'FCB-INPATH-AUS':
      return 'FCB_INPATH';
    case 'FCB-INPATH-NZL':
      return 'FCB_INPATH_NZ';
    default:
      throw new Error('Could not parse Inpath catalog');
  }
};

export const getClientNameForInpathPolicy = (catalog) => {
  switch (catalog) {
    case 'FCB-INPATH-AUS':
      return 'fctg.ws';
    case 'FCB-INPATH-NZL':
      return 'fctg-nz.ws';
    default:
      throw new Error('Could not parse Inpath catalog');
  }
};
