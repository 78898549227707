import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './policyTab.scss';
import Grid from '@mui/material/Grid';
import { format } from 'date-fns';
import { splitAndFilterString } from '../../../../shared/helpers/string.helper';
import { Divider } from '@mui/material';
import CurrencyComponent from '../../../../shared/components/Currency/Currency';
import useCountry from '../../../../shared/utils/useCountry';

const PolicyTab = ({ policyDetails }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({});
  let destination = '';

  try {
    const countryNamesSplitted =
      policyDetails?.policy?.genericData?.destinationCountry?.split(',');
    const rawCountryNames = countryNamesSplitted.map((name) =>
      useCountry(name),
    );
    const translatedCountryNames = rawCountryNames.map(
      (rawCountryName, index) =>
        t(
          `COUNTRY.${rawCountryName?.countryName?.toLowerCase()}`,
          rawCountryName?.countryName,
        ) || countryNamesSplitted[index],
    );
    destination = translatedCountryNames.join(', ');
  } catch (err) {
    console.error(err);
    destination = '';
  }

  useEffect(() => {
    if (policyDetails) {
      const initialValues = {
        subscriptionDate: format(
          new Date(policyDetails.policy.subscriptionDate),
          'dd/MM/yyyy',
        ),
        bookingDate: format(
          new Date(policyDetails.insuranceData.bookingDate),
          'dd/MM/yyyy',
        ),
        startDate: format(
          new Date(policyDetails.insuranceData.startDate),
          'dd/MM/yyyy',
        ),
        endDate: format(
          new Date(policyDetails.insuranceData.endDate),
          'dd/MM/yyyy',
        ),
        duration: `${policyDetails.insuranceData.duration} day(s)`,
        destination: destination || null,
        insuranceAddons:
          splitAndFilterString(
            policyDetails.policy.genericData.insuranceAddons,
          ) || t('COMMON.none'),
        insuranceOptions: (
          <CurrencyComponent
            value={policyDetails.policy.genericData.excess}
            countryCode={policyDetails.policy.price.currency}
          />
        ),

        //amountToInsure: policyDetails.insuranceData.amountToInsure > 0 ? `${policyDetails.insuranceData.amountToInsure} ${policyDetails.policy.price.currency}` : undefined,
        brand: policyDetails.policy.genericData.brand,
        product:
          policyDetails.policy.additionalValues[0]?.data?.productName ||
          policyDetails.product.productCode,
        salesAgent: policyDetails.policy.genericData.agentSales,
        affiliateOrAggregateId: policyDetails.policy.genericData.partnerCode,
        cancellationLimit: (
          <CurrencyComponent
            value={policyDetails.policy.genericData?.cancellationLimit}
            countryCode={policyDetails.policy.price.currency}
          />
        ),
        medicalIncrease: (
          <CurrencyComponent
            value={
              policyDetails.policy.additionalValues[0]?.data.medical_increase ||
              0
            }
            countryCode={policyDetails.policy.price.currency}
          />
        ),
        tripCost: policyDetails.policy.genericData?.amountToInsure ? (
          <CurrencyComponent
            value={policyDetails.policy.genericData.amountToInsure}
            countryCode={policyDetails.policy.price.currency}
          />
        ) : null,
      };
      setValues(initialValues);
    }
  }, [policyDetails, destination]);

  // Fields based on policyDetails
  const fields = [
    {
      name: 'brand',
      label: t('POLICY_DETAILS.POLICY.brand'),
    },
    {
      name: 'product',
      label: t('POLICY_DETAILS.POLICY.product'),
    },
    {
      name: 'startDate',
      label: t('POLICY_DETAILS.POLICY.startDate'),
    },
    {
      name: 'destination',
      label: t('POLICY_DETAILS.POLICY.destination'),
    },
    {
      name: 'endDate',
      label: t('POLICY_DETAILS.POLICY.endDate'),
    },
    {
      name: 'duration',
      label: t('POLICY_DETAILS.POLICY.duration'),
    },
    {
      name: 'subscriptionDate',
      label: t('POLICY_DETAILS.POLICY.purchaseDate'),
    },
    {
      name: 'insuranceOptions',
      label: t('POLICY_DETAILS.POLICY.insuranceOptions'),
    },
    {
      name: 'insuranceAddons',
      label: t('POLICY_DETAILS.POLICY.insuranceAddons'),
    },
    {
      name: 'cancellationLimit',
      label: t('POLICY_DETAILS.POLICY.cancellationLimit'),
    },
    {
      name: 'noop',
      label: '',
    },
    {
      name: 'medicalIncrease',
      label: t('POLICY_DETAILS.POLICY.medicalIncrease'),
    },
    ...(policyDetails.policy.genericData?.amountToInsure
      ? [{ name: 'tripCost', label: t('POLICY_DETAILS.POLICY.tripCost') }]
      : []),
  ];

  const salesAgentField = {
    name: 'salesAgent',
    label: t('POLICY_DETAILS.POLICY.salesAgent'),
    isEditable: false,
  };

  return (
    <div className="policyTab">
      <div className="header">
        <h3>{t('POLICY_DETAILS.details')}</h3>
      </div>
      <Grid container spacing={2}>
        {fields.map((field, index) => (
          <Grid item xs={12} md={6} key={index} className="field-container">
            <div className={'label-text'}>{field.label}</div>
            <div className="text-value">{values[field.name]}</div>
          </Grid>
        ))}
      </Grid>

      <Divider sx={{ marginTop: '3.5rem', marginBottom: '3rem' }} />

      <div className="header">
        <h3>{t('POLICY_DETAILS.consultant')}</h3>
        <div>
          <div className="text-value">{values[salesAgentField.name]}</div>
        </div>
      </div>
    </div>
  );
};

PolicyTab.propTypes = {
  policyDetails: PropTypes.object,
};

export default PolicyTab;
