import axios from 'axios';
import { getToken } from './token.helper';
import { useContext } from 'react';
import { UserDataContext } from 'shared/context/userDataContext';
import { useLocation } from 'react-router-dom';

export const usePortalApi = () => {
  const { selectedBusinessUnit, selectedBrand } = useContext(UserDataContext);
  const apiStrapiUrl = process.env.REACT_APP_STRAPI_API;

  const api = axios.create({
    baseURL: apiStrapiUrl,
  });

  api.interceptors.request.use(
    async (config) => {
      const token = getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      const params = new URLSearchParams(
        config.url.includes('?') ? config.url.split('?')[1] : '',
      );

      if (selectedBusinessUnit) {
        params.set('businessUnit', selectedBusinessUnit.shopId);
      }
      if (selectedBrand) {
        params.set('brand', selectedBrand.code);
      }

      const baseUrl = config.url.split('?')[0];
      config.url = `${baseUrl}?${params.toString()}`;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  return api;
};

export const useCm360Api = () => {
  const { channel, dataCenter, sessionContext } = useContext(UserDataContext);
  const { state } = useLocation();
  const apiUrl = dataCenter.cm360Endpoint;

  const api = axios.create({
    baseURL: apiUrl,
  });

  api.interceptors.request.use(
    async (config) => {
      const token = getToken();
      const headers = {
        'X-Api-Key': dataCenter.cm360Key,
        'X-Current-Session-Context': sessionContext,
        'Client-Id': channel.psClient || '',
        ...(token && { Authorization: `Bearer ${token}` }),
      };

      config.headers = {
        ...config.headers,
        ...headers,
      };

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  return api;
};
